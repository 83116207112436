<template>
	<div class="container">
		<policy-content :content="content"></policy-content>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import PolicyContent from '@/components/gadget/PolicyContent';

export default {
	components: {
		PolicyContent
	},
	data() {
		return {
			content: this.getContent()
		};
	},
	methods: {
		getContent() {
			const title = 'ข้อตกลงและเงื่อนไข การขาย';
			const content = `
				<p>ขอต้อนรับสู่เว็บไซต์ yellowtire และ/หรือ yellowtire mobile application (&ldquo;เว็บไซต์&rdquo;) จัดทำและบริหารงานโดย บริษัท เยลโลไทร์ จำกัด (&ldquo;บริษัทฯ &rdquo; &ldquo;เรา&rdquo; หรือ &ldquo;ของเรา&rdquo;) กรุณาอ่านและทำความเข้าใจ ข้อตกลงและเงื่อนไข การให้บริการ / ข้อตกลงและเงื่อนไข การขาย / การรับประกันสินค้า/การเคลมสินค้า / นโยบายการคืนสินค้า การยกเลิกสินค้า และ การคืนเงิน / นโยบายความเป็นส่วนตัว / นโยบายคุกกี้ โดยละเอียด ข้อกำหนดและเงื่อนไขเหล่านี้จะใช้บังคับกับการใช้งาน และการเข้าถึง และการใช้บริการแพลตฟอร์มของ yellowtire ด้วยการเข้าถึงแพลตฟอร์ม และ/หรือใช้บริการ ท่านตกลงที่จะผูกพันโดยข้อกำหนดเหล่านี้ หากท่านไม่ตกลงด้วยกับข้อกำหนดเหล่านี้ในการใช้บริการนี้ ห้ามเข้าใช้ และ/หรือใช้แพลตฟอร์มนี้ หรือใช้บริการ</p>

				<ol>
					<li>
						<strong>นิยาม และ คำจำกัดความ ในข้อกำหนดนี้</strong>
						<ol>
							<li>
								&ldquo;บริษัทฯ &rdquo; &ldquo;เรา&rdquo; หมายถึง บริษัท เยลโลไทร์ จำกัด ซึ่งจดทะเบียนถูกต้องตามประมวลกฎหมายแพ่งและพาณิชย์ สำนักงานตั้งอยู่เลขที่ อาคารรสา ทู ชั้น 18 เลขที่ 1818 ถนนเพชรบุรี แขวงมักกะสัน เขตราชเทวี กรุงเทพมหานคร 10400 ประเทศไทย
							</li>
							<li>
								&ldquo;เว็บไซต์ เยลโลไทร์&rdquo; หมายถึง เว็บไซต์รวมถึงแพลตฟอร์มต่างๆ ที่ดำเนินการโดยบริษัทฯ และสามารถเข้าถึงได้โดยอินเตอร์เน็ตผ่านทางตัวชี้แหล่งในอินเทอร์เน็ต (URL) https://www.yellowtire.com
							</li>
							<li>
								&ldquo;สินค้า&rdquo; หมายถึง สินค้าและบริการที่บริษัทฯ จัดหาให้ภายใต้ข้อกำหนดฉบับนี้
							</li>
							<li>
								&ldquo;สัญญา&rdquo; หมายถึง สัญญาซื้อขายในเว็บไซต์รวมถึงแพลตฟอร์ม yellowtire ซึ่งถูกก่อขึ้นและปรากฏเป็นหลักฐานด้วยใบสั่งซื้อของลูกค้า และใบยืนยันการสั่งซื้อ
							</li>
							<li>
								&ldquo;ผู้ซื้อ&rdquo; &ldquo;ลูกค้า&rdquo; &ldquo;คุณ&rdquo; &ldquo;ท่าน&rdquo; หมายถึง บุคคลที่ทำคำเสนอซื้อและได้รับการสนองรับจากบริษัทฯ และ บุคคลธรรมดาอายุ 20 ปีขึ้นไป หรือมิฉะนั้นต้องอยู่ภายใต้การดูแลของบิดามารดาหรือผู้ปกครองตามกฎหมาย&nbsp;
							</li>
							<li>
								&ldquo;ใบสั่งซื้อของลูกค้า&rdquo; &ldquo;คำสั่งซื้อ&rdquo; หมายถึง คำสั่งซื้อสินค้าของท่านที่ส่งผ่านแพลตฟอร์มตามเงื่อนไข &amp; ข้อกำหนดในการซื้อขาย หรือ การสั่งซื้อจากลูกค้า ซึ่งถูกส่งมายังบริษัทฯ ผ่านทางเว็บไซต์ yellowtire โดยการกรอกข้อมูลในกระบวนการทำคำสั่งซื้อที่ปรากฏในเว็บไซต์ yellowtire ตามรูปแบบและขั้นตอนที่กำหนดไว้ในเว็บไซต์ yellowtire
							</li>
							<li>
								&ldquo;ผู้ขาย&rdquo; &ldquo;ร้านค้า&rdquo; &ldquo;ศูนย์บริการ&rdquo; หมายถึง ร้านค้า หรือ ศูนย์บริการ ที่ได้รับการอนุญาตจาก yellowtire ให้ใช้แพลตฟอร์ม และ/หรือบริการ และ/หรือ ขายสินค้าให้แก่ลูกค้า&nbsp;
							</li>
							<li>
								&ldquo;ใบยืนยันการสั่งซื้อ&rdquo; หมายถึง การยืนยันเป็นลายลักษณ์อักษรจากบริษัทฯ ถึงการยอมรับใบสั่งซื้อสินค้าของลูกค้า ที่บริษัทฯ ได้ออกให้ก่อนวันจัดหาสินค้าให้ลูกค้า ใบยืนยันการสั่งซื้อนี้โดยหมายรวมถึง การส่งทางจดหมายอิเล็กทรอนิกส์ ทางโทรเลข ทางสายเคเบิ้ล ทางโทรสาร หรือวิธีการสื่อสารใดๆ ในทำนองเช่นว่านั้น
							</li>
						</ol>
					</li>
					<li>
						<strong>หลักทั่วไปของการขาย</strong>
						<ol>
							<li>
								บริษัทฯ ตกลงขาย และลูกค้าตกลงซื้อสินค้าตามที่กำหนดเอาไว้ในใบสั่งซื้อของลูกค้า และ/หรือ ใบยืนยันการสั่งซื้อ และให้ตกอยู่ภายใต้ข้อกำหนดฉบับนี้ซึ่งใช้บังคับกับสัญญา โดยไม่มีข้อยกเว้นในการนำข้อกำหนดและเงื่อนไขอื่นมาใช้
								ไม่ว่าข้อกล่าวอ้างดังกล่าวได้ถูกอ้างจากลูกค้าโดยตรงหรือโดยอ้อม
							</li>
							<li>
								ลูกค้ายอมรับว่า ข้อความบนเว็บไซต์ รายการราคาสินค้า รายการสินค้าสำหรับการขาย หรือข้อความที่เกี่ยวกับการขายใดๆ ที่เผยแพร่บนเว็บไซต์ yellowtire นั้น เป็นเพียงคำเชื้อเชิญเพื่อให้ทำคำเสนอ
								และไม่ถือเป็นคำเสนอหรือเป็นการสร้างภาระผูกพันของบริษัทฯ ในการขายสินค้าดังกล่าวให้แก่ลูกค้า
							</li>
							<li>
								บริษัทฯ ขอสงวนสิทธิ์ในการแก้ไขข้อผิดพลาด และข้อบกพร่องให้ถูกต้องโดยปราศจากความรับผิดของบริษัทฯ เกี่ยวกับความผิดพลาดที่เกิดจากการพิมพ์เอกสาร งานธุรการ หรือความผิดพลาดหรือข้อขาดตกบกพร่องที่เกิดขึ้นในข้อความบนเว็บไซต์ รายการราคาสินค้า
								รายการสินค้าสำหรับการขาย หรือข้อความที่เกี่ยวกับการขายใดๆ ที่เผยแพร่ ใบตอบรับคำเสนอซื้อ ใบแจ้งหนี้ เอกสารหรือข้อมูลใดๆ ที่ออกโดยบริษัทฯ
							</li>
						</ol>

					</li>
					<li>
						<strong>คำเสนอซื้อและการยืนยันการสั่งซื้อ</strong>
						<ol>
							<li>ใบสั่งซื้อของลูกค้าจะยังไม่ถือว่าได้รับการยอมรับจากบริษัทฯ จนกว่าบริษัทฯ ได้ออกใบยืนยันการสั่งซื้อให้แก่ลูกค้า</li>
							<li>
								ลูกค้าเป็นฝ่ายรับผิดชอบกับบริษัทฯ ในการยืนยันความถูกต้องของข้อความในใบสั่งซื้อของลูกค้าที่ได้ยื่นโดยลูกค้า และในการให้ข้อมูลที่จำเป็นแก่บริษัทฯ เกี่ยวกับสินค้าในระยะเวลาอันสมควร เพื่อให้บริษัทฯ
								สามารถจัดเตรียมสัญญาที่สอดคล้องกับข้อตกลงนั้นได้ บริษัทฯ จะไม่รับผิดชอบการกระทำใดๆ ก็ตามที่เป็นผลมาจากการให้ข้อมูลที่ไม่ถูกต้องของลูกค้า
							</li>
							<li>จำนวน คุณภาพ คำอธิบาย และการเฉพาะเจาะจงคุณสมบัติ (สเปค) ของสินค้า จะถูกแจ้งไว้ในใบยืนยันการสั่งซื้อ</li>
							<li>
								บริษัทฯ ขอสงวนสิทธิในการเปลี่ยนสินค้า หรือข้อเฉพาะเจาะจงคุณสมบัติ (สเปค) ของสินค้าที่เกี่ยวข้อง และลักษณะการออกแบบ ในเวลาใดๆ โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
								และไม่ว่าจะเป็นผลมาจากการแก้ไขกฎหมายหรือเป็นดุลยพินิจโดยแท้ของบริษัทฯ&nbsp;
							</li>
						</ol>
					</li>
					<li>
						<strong>ราคาสินค้า</strong>

						<ol>
							<li>
								ราคาของสินค้าจะถูกกำหนดโดยผู้ขาย หรือ บริษัทฯ ในใบยืนยันการสั่งซื้อ หรือในกรณีที่ใบยืนยันการสั่งซื้อไม่ได้ระบุราคาไว้ ให้ยึดถือราคาที่กำหนดเอาไว้ในรายการราคาสินค้าของบริษัทฯ หรือข้อความบนเว็บไซต์ yellowtire
								ในวันและเวลาที่ได้ออกใบยืนยันการสั่งซื้อ
							</li>
							<li>ราคาสินค้าเป็นราคาที่รวมภาษีมูลค่าเพิ่ม ส่วนค่าติดตั้ง การรับประกันหลังการขาย และบริการหลังการขาย ซึ่งแตกต่างกันไปตาม ผู้ขาย โดยเงื่อนไขเป็นไปตามเงื่อนไขที่บริษัทกำหนด&nbsp;</li>
						</ol>
					</li>
					<li>
						<strong>ข้อกำหนดการชำระเงิน</strong>
						<ol>
							<li>
								ภายใต้ข้อตกลงเป็นลายลักษณ์อักษรกรณีพิเศษระหว่างลูกค้ากับบริษัทฯ บริษัทฯ มีสิทธิได้รับชำระเงินเต็มจำนวนของราคาสินค้า (ซึ่งรวมภาษีมูลค่าเพิ่มแล้ว) ในวันที่มีการทำใบสั่งซื้อของลูกค้า โดยบริษัทฯ
								มีสิทธิหักบัญชีจากบัตรเครดิตหรือบัตรเดบิตของลูกค้าเต็มจำนวนพร้อมด้วยภาษีมูลค่าเพิ่ม ในหรือภายหลังวันที่ทำใบสั่งซื้อของลูกค้า
							</li>
							<li>
								ลูกค้าจะต้องชำระเงินตามราคาของสินค้าในวันที่มีการทำใบสั่งซื้อของลูกค้า บริษัทฯ มีสิทธิที่จะได้รับชำระราคาของสินค้า โดยไม่ต้องคำนึงว่า ได้มีการส่งมอบสินค้าหรือกรรมสิทธิ์ในทรัพย์สินได้โอนไปยังลูกค้าแล้วหรือไม่ ทั้งนี้
								กำหนดเวลาในการชำระราคาถือเป็นสาระสำคัญของสัญญา และจะมีการออกใบเสร็จรับเงินให้
							</li>
							<li>
								ถ้าลูกค้ามิได้ชำระเงินตามกำหนดเวลา หรือการชำระเงินถูกปฏิเสธหรือไม่ได้รับการรับรองโดยธนาคารผู้ออกบัตรเครดิตหรือบัตรเดบิตของลูกค้าในระหว่างการดำเนินการเรียกเก็บของบริษัทฯ
								นอกเหนือการกระทำใดที่กระทบกระเทือนสิทธิอื่นใดของบริษัทหรือสิทธิเรียกร้องตามกฎหมายของบริษัทฯ แล้ว บริษัทฯ มีสิทธิยกเลิกสัญญาหรือระงับการส่งมอบสินค้าให้กับลูกค้า
							</li>
						</ol>
					</li>
					<li>
						<strong>การส่งมอบ และ/หรือติดตั้งสินค้า</strong>
						<ol>
							<li>การส่งมอบสินค้าถือว่าได้กระทำเมื่อสินค้าได้ถูกติดตั้งกับยานพาหนะของลูกค้า โดยศูนย์บริการของผู้ขายที่ระบุเอาไว้ในใบยืนยันการสั่งซื้อ หรือสถานที่อื่นใดที่บริษัทฯ ได้รับการอนุมัติล่วงหน้าแล้ว</li>
							<li>
								วันและเวลาที่แจ้งสำหรับการส่งมอบ และ/หรือติดตั้งสินค้า เป็นเพียงการประมาณการเท่านั้น มิใช่วันที่รับรอง และบริษัทฯ ไม่ต้องรับผิดชอบต่อความล่าช้าในการส่งมอบสินค้า และ/หรือการติดตั้งสินค้า ไม่ว่ากรณีใด ทั้งนี้
								กำหนดเวลาส่งมอบหรือการติดตั้งไม่ถือเป็นสาระสำคัญของสัญญา เว้นแต่ ได้มีการตกลงกับบริษัทฯ เป็นลายลักษณ์อักษร
							</li>
							<li>
								ในวันติดตั้งสินค้าที่ศูนย์บริการที่ลูกค้าเลือก ลูกค้าจะต้องยืนยันตัวตนว่าเป็นบุคคลเดียวกับที่ปรากฏชื่อในใบยืนยันการสั่งซื้อ โดยแสดงใบเสร็จรับเงินทางอีเมล และสมุดทะเบียนรถ
								ในกรณีที่ทะเบียนรถไม่ตรงตามข้อมูลที่ระบุไว้ในใบยืนยันการสั่งซื้อ ลูกค้าต้องแจ้งให้บริษัทฯ ทราบก่อนทำการติดตั้งสินค้า มิฉะนั้น บริษัทฯ และศูนย์บริการมีสิทธิปฏิเสธการติดตั้ง
							</li>
						</ol>
					</li>
					<li>
						<strong>การจัดส่ง หรือ การส่งมอบ</strong>
						<ol>
							<li>
								ท่านรับทราบและตกลงว่าการจัดส่งสินค้าทั้งหมดในประเทศไทย จะให้บริการหรือบริหารจัดการโดยบริษัทขนส่งเอกชน หรือตัวแทนที่ได้รับอนุญาตโดย yellowtire ท่านตกลงที่จะผูกพันกับข้อตกลงการให้บริการของบริษัทฯ
								และ/หรือนโยบายอื่นๆที่เกี่ยวข้องตามที่ระบุไว้ในนโยบายของบริษัทฯ&nbsp;
							</li>
							<li>
								บริษัทฯ จะแจ้งให้ผู้ขายทราบเมื่อ บริษัทฯ ได้รับเงินซื้อสินค้าของเงินซื้อสินค้าของผู้ซื้อแล้ว หลังจากนั้นผู้ขายควรดำเนินการตามที่จำเป็นเพื่อจัดส่งสินค้าที่ซื้อไปยังผู้ซื้อ พร้อมทั้งแจ้งรายละเอียด เช่น ชื่อบริษัทที่จัดส่ง
								หมายเลขติดตามพัสดุ ฯลฯ ให้แก่ผู้ซื้อผ่านทางไซต์
							</li>
						</ol>
					</li>
					<li>
						<strong>ความรับผิด</strong>
						<ol>
							<li>
								บริษัทฯ ไม่อยู่ภายใต้ความรับผิดชอบเกี่ยวกับความชำรุดบกพร่องของสินค้าที่เกิดจากความไม่ถูกต้องของใบสั่งซื้อของลูกค้า และบริษัทฯ ไม่ต้องรับผิดชอบในข้อผิดพลาดในใบสั่งซื้อของลูกค้า หรือการสูญหายหรือเสียหายใดๆ
								ที่เกิดจากความผิดพลาดดังกล่าว
							</li>
							<li>
								บริษัทฯ ไม่อยู่ภายใต้ความรับผิดชอบเกี่ยวกับความชำรุดบกพร่องของสินค้าที่เกิดจากการสึกหรอตามสภาพ การทำให้เสียหายโดยจงใจ ความประมาท สภาพการใช้งานที่ผิดปกติ การไม่ปฏิบัติตามวิธีใช้ของบริษัทฯ
								(ไม่ว่าด้วยวาจาหรือเป็นลายลักษณ์อักษร) การใช้อย่างไม่ถูกต้อง การดัดแปลง หรือซ่อมแซมสินค้า โดยไม่ได้รับอนุมัติจากบริษัทฯ&nbsp;
							</li>
							<li>ดังที่ได้กล่าวโดยชัดแจ้งในข้อกำหนดฉบับนี้ บรรดาการรับประกัน เงื่อนไข หรือข้อกำหนดใดๆ ที่กฎหมายรับรองไว้โดยนัย จะไม่นำมาใช้บังคับกับจำนวนสูงสุดที่กฎหมายอนุญาต</li>
							<li>
								ข้อเรียกร้องใดๆ ของลูกค้าที่อ้างถึงความชำรุดบกพร่องในคุณภาพหรือสภาพของสินค้า หรือการไม่เป็นไปตามข้อเฉพาะเจาะจงคุณสมบัติ (สเปค) ของสินค้า จะต้องบอกกล่าวให้บริษัทฯ ทราบทันทีหลังจากตรวจสอบรับมอบสินค้า หรือก่อนการติดตั้ง
								แล้วแต่กรณี
							</li>
							<li>
								ในกรณีที่บริษัทฯ เป็นผู้ต้องรับผิดตามข้อเรียกร้องเกี่ยวกับความเสียหายที่เกิดขึ้นกับยานพาหนะ หรือล้อในขณะที่กำลังรับการบริการ ศูนย์บริการมีโอกาสที่จะตรวจสอบและพิสูจน์ถึงความเสียหายตามข้อเรียกร้อง
								ในกรณีที่ศูนย์บริการเดิมดังกล่าวเป็นสถานที่ที่ลูกค้าไม่สะดวก บริษัทฯ จะแต่งตั้งศูนย์บริการอื่นเพื่อดูแลแทน การตรวจสอบใดๆ ที่กระทำขึ้นโดยบุคคลภายนอกโดยไม่ได้รับการกำกับควบคุมจากศูนย์บริการที่บริษัทฯ แต่งตั้ง
								หรือไม่ได้รับการตกลงจากบริษัทฯ เป็นลายลักษณ์อักษรก่อนการกระทำเช่นว่านั้นจะเสร็จสิ้น บริษัทฯ ไม่ต้องรับผิดชอบ
							</li>
							<li>ถ้าการส่งมอบสินค้าไม่ได้มีการถูกยกเลิก และลูกค้าไม่ได้บอกกล่าวบริษัทฯ ลูกค้าย่อมไม่มีสิทธิปฏิเสธสินค้า และบริษัทจะไม่มีความรับผิดใดๆ ต่อความชำรุดบกพร่องและข้อผิดพลาดดังกล่าว</li>
							<li>
								ข้อเรียกร้องใดๆ ที่สมเหตุสมผลเกี่ยวกับความชำรุดบกพร่องในคุณภาพหรือสภาพของสินค้า หรือการไม่เป็นไปตามข้อเฉพาะเจาะจงคุณสมบัติ (สเปค) ของสินค้า ที่ลูกค้าได้บอกกล่าวบริษัทฯ ตามข้อกำหนดนี้ บริษัทฯ
								มีสิทธิในการใช้ดุลพินิจโดยฝ่ายเดียวว่าจะส่งสินค้าทดแทน (ทั้งหมดหรือบางส่วน) โดยไม่คิดค่าใช้จ่าย หรือส่งมอบเงินคืนให้กับลูกค้าตามราคาของสินค้า (หรือตามสัดส่วน) แต่บริษัทฯ
								ไม่ต้องรับผิดนอกเหนือไปจากที่กล่าวนี้ต่อลูกค้าหรือบุคคลอื่นใด
							</li>
							<li>
								นอกเหนือจากการเสียชีวิตหรือบาดเจ็บที่เป็นผลมาจากความประมาทเลินเล่อของบริษัทฯ บริษัทฯ ไม่ต้องรับผิดต่อลูกค้าจากการกระทำของตัวแทน (ยกเว้นกรณีฉ้อฉล) หรือข้อรับประกัน เงื่อนไข หรือข้อตกลงอื่นๆ ที่ไม่แจ้งชัด หรือหน้าที่ใดๆ
								ตามกฎหมาย หรือ (ภายใต้ข้อกำหนดที่ชัดแจ้งในสัญญา) ความสูญหายหรือเสียหายพิเศษที่มิใช่ผลโดยตรง (ไม่ว่ากำไรที่หายไปหรือความเสียหายอื่นใด) ต้นทุน ค่าใช้จ่าย หรือสิทธิเรียกร้องค่าสินไหมทดแทนใดๆ
								(ไม่ว่าเกิดจากความประมาทเลินเล่อของบริษัทฯ พนักงาน ตัวแทน หรือบุคคลใดก็ตาม) ที่เกิดขึ้นและเกี่ยวเนื่องกับการจัดหาและการติดตั้งสินค้า หรือการใช้งาน หรือการขายต่อของลูกค้า และความรับผิดทั้งหมดของบริษัทฯ ตามสัญญา
								มากที่สุดต้องไม่เกินราคาสินค้า เว้นแต่มีบทบัญญัติเป็นอย่างอื่นในข้อกำหนดนี้
							</li>
							<li>
								บริษัทฯ ไม่ต้องรับผิดต่อลูกค้าหรือไม่ถือว่ากระทำผิดสัญญา หากมีเหตุผลมาจากความล่าช้าในการดำเนินการ หรือการที่บริษัทฯ ไม่สามารถดำเนินการใดๆ ให้ได้ หรือภาระหน้าที่ของบริษัทฯ อันเกี่ยวเนื่องกับสินค้า และความล่าช้า
								หรือความล้มเหลวนั้น เกิดจากเหตุสุดวิสัย หรือสาเหตุอื่นใดที่นอกเหนือการควบคุมตามสมควรของบริษัทฯ&nbsp;
							</li>
						</ol>
					</li>
					<li>
						<strong>ข้อกำหนดทั่วไป</strong>
						<ol>
							<li>
								สัญญาและข้อกำหนดฉบับนี้ ถือเป็นข้อตกลงทั้งหมด ระหว่างลูกค้าและบริษัทฯ ที่เกี่ยวกับการจัดหาสินค้า และมีผลใช้บังคับเหนือถ้อยแถลงด้วยวาจา หรือการนำเสนอใดๆ หรือข้อตกลงใดๆ ที่เป็นลายลักษณ์อักษร กล่าวโดยเฉพาะ
								ลูกค้าไม่อาจยึดถือคำแถลงใดๆ ที่ได้กระทำโดยพนักงาน หรือตัวแทนของบริษัทฯ&nbsp;
							</li>
							<li>
								บริษัทฯ อาจโอนสิทธิในสัญญาที่มีกับลูกค้า หรือช่วงสิทธิของสัญญาทั้งหมดหรือบางส่วนให้กับบุคคลอื่น (ในทางกฎหมายหรืออย่างอื่น) หรือองค์กรอื่น และฝ่ายลูกค้าไม่สามารถโอนสิทธิในสัญญาไม่ว่าประโยชน์หรือภาระผูกพันใดๆ
								ตามสัญญากับบริษัทฯ ไปให้บุคคลอื่นได้ เว้นแต่จะมีการตกลงเป็นลายลักษณ์อักษร
							</li>
							<li>
								หากเกิดข้อผิดพลาดในการดำเนินธุรกรรมใดๆ ท่านอนุญาตให้เราทำการแก้ไขปรับปรุงรายการโดยการหักเงิน (เดบิต) จากหรือคืนเงิน (เครดิต) สู่บัญชีธนาคารที่ท่านกำหนดเพื่อแก้ไขความผิดพลาดดังกล่าว ทั้งนี้
								การแก้ไขปรับปรุงจะต้องเป็นตามกฎหมายและกฎเกณฑ์ที่มีผลบังคับใช้ หากเราไม่สามารถทำการหักเงินจากบัญชีของท่านได้ไม่ว่ากรณีใดๆ ท่านอนุญาตให้เราหักเงินรวมถึงค่าธรรมเนียมใดๆ จากบัญชี
								หรือตราสารการจ่ายเงินอื่นใดที่ท่านได้แจ้งไว้ต่อเรา หรือให้หักเงินและค่าธรรมเนียมใดๆ
							</li>
						</ol>
					</li>
				</ol>
			`;

			return {
				title: title,
				detail: content
			};
		}
	},
	metaInfo() {
		const title = 'ข้อตกลงและเงื่อนไข การขาย | YELLOWTiRE';
		const desc = 'ข้อตกลงและเงื่อนไข การขาย YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
    }
}
</script>